<template>
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
  >
    <path
      d="M26.07 16.185L22.77 12.885C22.35 12.465 22.23 11.88 22.395 11.355C22.95 9.675 23.25 7.875 23.25 6C23.25 5.175 23.925 4.5 24.75 4.5H30C30.825 4.5 31.5 5.175 31.5 6C31.5 20.085 20.085 31.5 6 31.5C5.175 31.5 4.5 30.825 4.5 30V24.765C4.5 23.94 5.175 23.265 6 23.265C7.86 23.265 9.675 22.965 11.355 22.41C11.88 22.23 12.48 22.365 12.885 22.77L16.185 26.07C20.43 23.895 23.91 20.43 26.07 16.185Z"
      fill="black"
    />
  </svg>
</template>

<style scoped>
.svg-icon path {
  fill: var(--default-font-color);
}
</style>
