<template>
  <BaseButton :to="to" :disabled="isDisabled" :label="''" class="button">
    <SvgIconsPhone24 v-if="size === 'small'" />
    <SvgIconsPhone36 v-else />
  </BaseButton>
</template>

<script>
import BaseButton from "@/components/UI/Base/BaseButton.vue";

import SvgIconsPhone24 from "@/components/Svg/Icons/SvgIconsPhone24.vue";
import SvgIconsPhone36 from "@/components/Svg/Icons/SvgIconsPhone36.vue";

export default {
  components: { BaseButton, SvgIconsPhone24, SvgIconsPhone36 },

  props: {
    to: {
      type: [String, Object],
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "medium",
    },
  },
};
</script>

<style scoped>
.button {
  opacity: 0.4;
}
.button:hover {
  opacity: 1;
}
@media (max-width: 1200px) {
  .button {
    opacity: 1;
  }
}
</style>
