<template>
  <div class="nav-mobile-details-wrapper">
    <nav class="nav-mobile-details">
      <BaseButtonIconArrowBack
        @click="$router.back()"
        :size="'small'"
        class="button-icon nav-mobile-details__button-icon"
      />
      <ButtonCtaResponse
        v-if="!isArchived"
        @click="openPopupReply"
        :size="'x-small'"
      />
      <BaseButtonIconPhone
        v-if="offer && offer.details.phone?.length > 0 && !isArchived"
        @click="openPopupCall"
        :size="'small'"
        class="button-icon nav-mobile-details__button-icon"
      />
    </nav>

    <OfferNannyDetailsPopupReply
      v-if="canShowPopupReply"
      ref="popupReplyRef"
      :from-application-nanny-id="FROM_APPLICATION_ID"
      :on-offer-nanny-id="OFFER_ID"
    />

    <OfferNannyDetailsPopupCall
      v-if="canShowPopupCall"
      ref="popupCallRef"
      :parent-phone="offer.details.phone"
      :offer-id="OFFER_ID"
    />

    <PopupPushToRegistration
      v-if="isAuth === false"
      ref="popupPushToRegistrationRef"
      :description="'I wypełnij profil aby odpowiedzieć na tę ofertę'"
    />

    <OfferNannyPopupPushToCreateApplication
      v-if="isAuth"
      ref="popupPushToCreateApplicationRef"
    />
  </div>
</template>

<script>
import useOfferNannyDetails from "@/composables/useOfferNannyDetails";
import useAuth from "@/composables/useAuth";

import BaseButtonIconArrowBack from "@/components/UI/Base/BaseButtonIconArrowBack.vue";
import ButtonCtaResponse from "@/components/UI/ButtonCtaResponse.vue";
import BaseButtonIconPhone from "@/components/UI/Base/BaseButtonIconPhone.vue";
import OfferNannyDetailsPopupReply from "@/components/Offer/Nanny/Details/OfferNannyDetailsPopupReply.vue";
import OfferNannyDetailsPopupCall from "@/components/Offer/Nanny/Details/OfferNannyDetailsPopupCall.vue";
import PopupPushToRegistration from "@/components/PopupPushToRegistration.vue";
import OfferNannyPopupPushToCreateApplication from "@/components/Offer/Nanny/OfferNannyPopupPushToCreateApplication.vue";

export default {
  components: {
    BaseButtonIconArrowBack,
    ButtonCtaResponse,
    BaseButtonIconPhone,
    OfferNannyDetailsPopupReply,
    OfferNannyDetailsPopupCall,
    PopupPushToRegistration,
    OfferNannyPopupPushToCreateApplication,
  },

  setup() {
    const { isAuth } = useAuth();
    const {
      FROM_APPLICATION_ID,
      OFFER_ID,
      offer,
      isArchived,

      popupReplyRef,
      openPopupReply,
      canShowPopupReply,

      popupCallRef,
      openPopupCall,
      canShowPopupCall,

      popupPushToRegistrationRef,
      popupPushToCreateApplicationRef,
    } = useOfferNannyDetails();

    return {
      FROM_APPLICATION_ID,
      OFFER_ID,

      isAuth,
      offer,
      isArchived,

      popupReplyRef,
      openPopupReply,
      canShowPopupReply,

      popupCallRef,
      openPopupCall,
      canShowPopupCall,

      popupPushToRegistrationRef,
      popupPushToCreateApplicationRef,
    };
  },
};
</script>

<style scoped>
.nav-mobile-details-wrapper {
  width: 100vw;

  position: fixed;
  bottom: 0;
  left: 0;

  background-color: var(--main-bg-color);
  border-top: 1px solid rgba(22, 32, 94, 0.06);
}
.nav-mobile-details {
  width: var(--layout-default--width);
  height: 50px;

  margin: 0 auto;

  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}
.nav-mobile-details__button-icon {
  width: calc((100% - 3 * 10px) / 4);
  opacity: 0.4;
}
.nav-mobile-details__button-icon:active {
  opacity: 1;
}
</style>
